<template>
  <div class="mt-2 pm-container card p-4">
    <div>
      <router-link class="btn btn-success" to="/account/neu">Account hinzufügen</router-link>
    </div>
    <hr>

    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1">🔎</span>
      <input v-model.lazy="search" v-debounce="500" class="form-control" type="text"
             placeholder="Nach Name oder Email suchen">
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Erstellt am</th>
          <th scope="col">Administrator</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="a in accounts" v-bind:key="a.email">
          <th scope="row"></th>
          <td>{{ a.name }}</td>
          <td>{{ a.email }}</td>
          <td>{{ $filters.datetime(a.created) }}</td>
          <td>{{ a.isAdmin ? "X" : "" }}</td>
          <td>
            <router-link class="btn btn-primary btn-sm" :to="`/account/${a.id}/bearbeiten`">Bearbeiten</router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accounts",

  data() {
    return {
      search: ""
    }
  },

  mounted() {
    this.doSearch();
  },

  watch: {
    search() {
      this.doSearch();
    }
  },

  methods: {
    doSearch() {
      this.$store.dispatch("account/loadAccounts", {search: this.search});
    }
  },

  computed: {
    accounts() {
      return this.$store.state.account.accounts;
    }
  }
}
</script>

<style scoped>

</style>